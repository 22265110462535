import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c0d00738 = () => interopDefault(import('../pages/casestudy.vue' /* webpackChunkName: "pages/casestudy" */))
const _9a6c9910 = () => interopDefault(import('../pages/edukacije.vue' /* webpackChunkName: "pages/edukacije" */))
const _4f5095b9 = () => interopDefault(import('../pages/novosti.vue' /* webpackChunkName: "pages/novosti" */))
const _7001ef2c = () => interopDefault(import('../pages/o-nama.vue' /* webpackChunkName: "pages/o-nama" */))
const _73e28ab2 = () => interopDefault(import('../pages/stipendija.vue' /* webpackChunkName: "pages/stipendija" */))
const _0a8308ae = () => interopDefault(import('../pages/autor/_slug.vue' /* webpackChunkName: "pages/autor/_slug" */))
const _3de653af = () => interopDefault(import('../pages/edukacija/_slug.vue' /* webpackChunkName: "pages/edukacija/_slug" */))
const _563ec99b = () => interopDefault(import('../pages/novost/_slug.vue' /* webpackChunkName: "pages/novost/_slug" */))
const _e71ca70c = () => interopDefault(import('../pages/pretraga/_slug.vue' /* webpackChunkName: "pages/pretraga/_slug" */))
const _166ff94f = () => interopDefault(import('../pages/study/_slug.vue' /* webpackChunkName: "pages/study/_slug" */))
const _6acdbcc6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/casestudy",
    component: _c0d00738,
    name: "casestudy"
  }, {
    path: "/edukacije",
    component: _9a6c9910,
    name: "edukacije"
  }, {
    path: "/novosti",
    component: _4f5095b9,
    name: "novosti"
  }, {
    path: "/o-nama",
    component: _7001ef2c,
    name: "o-nama"
  }, {
    path: "/stipendija",
    component: _73e28ab2,
    name: "stipendija"
  }, {
    path: "/autor/:slug?",
    component: _0a8308ae,
    name: "autor-slug"
  }, {
    path: "/edukacija/:slug?",
    component: _3de653af,
    name: "edukacija-slug"
  }, {
    path: "/novost/:slug?",
    component: _563ec99b,
    name: "novost-slug"
  }, {
    path: "/pretraga/:slug?",
    component: _e71ca70c,
    name: "pretraga-slug"
  }, {
    path: "/study/:slug?",
    component: _166ff94f,
    name: "study-slug"
  }, {
    path: "/",
    component: _6acdbcc6,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

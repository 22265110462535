
    export default {
        data(){
            return {
                search_term: ''
            }
        },
        props: ['mobile'],
        created(){},
        mounted() {},
        methods: {
            handleSearch(event){
                if (event.key == 'Enter') {
                    if(this.search_term.trim().length > 0) this.search()
                }
            },

            search(){
                this.$router.push(`/pretraga/${this.search_term.toLowerCase()}`)
                this.search_term = '';
            }
        }
    }

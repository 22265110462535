
 export default {
    data: function () {
        return {
            loading: null,
            mobile_menu_open: false,
            // products_order: ''
        };
    },
    watch: {
        $route(to, from) {
            // console.log('route change to', to)
            // console.log('route change from', from)
            this.mobile_menu_open = false;
        },
    },
    methods: {
        toggleMobileMenu() {
            this.mobile_menu_open = !this.mobile_menu_open;
        }
    },
  }
  

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/Inter-Black.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/Inter-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/Inter-ExtraBold.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/Inter-ExtraLight.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./fonts/Inter-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./fonts/Inter-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./fonts/Inter-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./fonts/Inter-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./fonts/Inter-Thin.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:InterBlack;font-weight:900;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-display:swap}@font-face{font-family:Inter;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");font-display:swap}@font-face{font-family:Inter;font-weight:800;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");font-display:swap}@font-face{font-family:Inter;font-weight:200;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");font-display:swap}@font-face{font-family:Inter;font-weight:300;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");font-display:swap}@font-face{font-family:Inter;font-weight:500;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");font-display:swap}@font-face{font-family:Inter;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ");font-display:swap}@font-face{font-family:Inter;font-weight:600;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ");font-display:swap}@font-face{font-family:Inter;font-weight:100;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ");font-display:swap}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=142410a6&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=142410a6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "142410a6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchBar: require('/var/www/html/nod.ba_nuxt/components/SearchBar.vue').default})

export const Advert = () => import('../../components/Advert.vue' /* webpackChunkName: "components/advert" */).then(c => wrapFunctional(c.default || c))
export const AsyncImage = () => import('../../components/AsyncImage.vue' /* webpackChunkName: "components/async-image" */).then(c => wrapFunctional(c.default || c))
export const ExampleComponent = () => import('../../components/ExampleComponent.vue' /* webpackChunkName: "components/example-component" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const NewsSlider = () => import('../../components/NewsSlider.vue' /* webpackChunkName: "components/news-slider" */).then(c => wrapFunctional(c.default || c))
export const NewsletterCard = () => import('../../components/NewsletterCard.vue' /* webpackChunkName: "components/newsletter-card" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const RegistrationForm = () => import('../../components/RegistrationForm.vue' /* webpackChunkName: "components/registration-form" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const AnimationsCheckmark = () => import('../../components/Animations/Checkmark.vue' /* webpackChunkName: "components/animations-checkmark" */).then(c => wrapFunctional(c.default || c))
export const EducationsCard = () => import('../../components/Educations/Card.vue' /* webpackChunkName: "components/educations-card" */).then(c => wrapFunctional(c.default || c))
export const EducationsFeaturedImages = () => import('../../components/Educations/FeaturedImages.vue' /* webpackChunkName: "components/educations-featured-images" */).then(c => wrapFunctional(c.default || c))
export const EducationsSkeleton = () => import('../../components/Educations/Skeleton.vue' /* webpackChunkName: "components/educations-skeleton" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/Layout/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/Layout/Header.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutPageHeader = () => import('../../components/Layout/PageHeader.vue' /* webpackChunkName: "components/layout-page-header" */).then(c => wrapFunctional(c.default || c))
export const NewsCard = () => import('../../components/News/Card.vue' /* webpackChunkName: "components/news-card" */).then(c => wrapFunctional(c.default || c))
export const NewsMostRead = () => import('../../components/News/MostRead.vue' /* webpackChunkName: "components/news-most-read" */).then(c => wrapFunctional(c.default || c))
export const NewsNewest = () => import('../../components/News/Newest.vue' /* webpackChunkName: "components/news-newest" */).then(c => wrapFunctional(c.default || c))
export const NewsSkeleton = () => import('../../components/News/Skeleton.vue' /* webpackChunkName: "components/news-skeleton" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

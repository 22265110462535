

    import Header from '../components/Layout/Header.vue';
    import Footer from '../components/Layout/Footer.vue';

    export default {
        data: function () {
            return {};
        },
        props: ["errors"],
        beforeCreate() {
            //console.log('setup');
        },
        created() { },
        beforeMount() {
            //console.log('mounting');
        },
        mounted() {},
        methods: {},
        components: { Header, Footer }
    }

